import React, { Ref } from "react";
import { FieldError } from "react-hook-form";

export interface SelectInputProps {
  label?: string;
  placeholder?: string;
  name: string;
  defaultValue?: string;
  options: { value: string; label: string }[];
  description?: string;
  error?: FieldError;
  children?: React.ReactNode;
  tabIndex?: number;
}

const SelectInput = React.forwardRef<
  HTMLSelectElement,
  SelectInputProps
>(
  (
    {
      label,
      placeholder,
      name,
      error,
      description,
      options,
      defaultValue,
      children,
      tabIndex
    },
    ref
  ) => {
    return (
      <div>
        <label>
          {children ?? <div className="text-sm font-semibold">{label}</div>}
          <select
            tabIndex={tabIndex}
            ref={ref}
            name={name}
            className={`w-full border border-gray-400 rounded py-1 px-2 bg-white  ${
              error && "border-red-600"
            }`}
            defaultValue={defaultValue}
          >
            {placeholder && (
              <option hidden value={null}>
                {placeholder}
              </option>
            )}
            {options.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </label>
        <div className="text-sm">{description}</div>
        <div className="text-sm text-red-600">{error?.message}</div>
      </div>
    );
  }
);

export { SelectInput };
