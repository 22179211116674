import React, { CSSProperties } from "react"

type ContainerProps = {
  containerClassName?: string
  maxWidth?: string
  style?: CSSProperties
}

const Container: React.FC<ContainerProps> = ({
  containerClassName,
  maxWidth,
  children,
  style,
}) => {
  return (
    <div
      className={`px-4 lg:px-8 flex justify-center items-center  ${containerClassName}`}
      style={style}
    >
      <div className="w-full" style={{ maxWidth: maxWidth ?? "1200px" }}>
        {children}
      </div>
    </div>
  )
}

export { Container }
